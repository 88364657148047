//main//
import React, { useEffect, useState } from 'react';
//material//
import { 
    Tabs,
    Tab,
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
//component//
import MainPageData from './MainPageData';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MainPage() {
    
    return (
        <>
            <MainPageData />
        </>
    );
}

export default MainPage;
