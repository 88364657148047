import React, { createContext, useContext, useState, useEffect } from 'react';
import { translateText } from '../services/translateService';
import { useLocation } from 'react-router-dom';

// 1. Membuat Context
const TranslationContext = createContext();

// 2. Hook untuk menggunakan Context
// export const useTranslation = () => useContext(TranslationContext);
export const useTranslation = () => {
    const context = useContext(TranslationContext);
    // console.warn('useTranslation context:', context);
    return context;
  };

// 3. Provider untuk menyediakan Context ke komponen anak
export const TranslationProvider = ({ children }) => {
  const location = useLocation();
  const [originalText, setOriginalText] = useState([]);
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('id'); // Default language is Indonesian

  // Fungsi untuk menerjemahkan semua teks
  const translateAllText = async (texts) => {
    const translatedTexts = await translateText(texts, language);
    const newTranslations = texts.reduce((acc, text, idx) => {
      acc[text] = translatedTexts[idx];
      return acc;
    }, {});
    setTranslations(newTranslations);
    return newTranslations; // Return the new translations
  };

  const handleTranslate = async () => {
    setLanguage('en');
  };

  const resetTranslate = async () => {
    setLanguage('id');
  };

  // Menyediakan nilai untuk Context
  return (
    <TranslationContext.Provider value={{ translations, language, setLanguage, translateAllText, handleTranslate, resetTranslate }}>
        {/* {console.warn('TranslationProvider values:', { translations, setLanguage, translateAllText })} */}
      {children}
    </TranslationContext.Provider>
  );
};
