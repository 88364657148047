import React, {useEffect,useState,useRef} from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Typography,
    Pagination,
    CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import Hero from '../../components/HeroSlider/Hero';
import HeroRsuite from '../../components/HeroRsuite/HeroRsuite';
import Section1 from '../../components/Section1/Section1';
import Section2 from '../../components/Section2/Section2';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
import food_1 from '../../assets/images/food_png/1.png';
import food_2 from '../../assets/images/food_png/2.png';
import food_3 from '../../assets/images/food_png/3.png';
import img_1 from '../../assets/images/fnb/food_1.png';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
//REDUX//
import { useDispatch } from "react-redux";
import { setOtherNews } from "../../redux/actions/app.action";
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
import { motion, Variants } from "framer-motion";
import './CardExperimental.css';

const cardVariants = {
    offscreen: {
        x: -400
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
  };

function GlobalPromoAndNews() {
    const cardRef = useRef(null);
    let { id } = useParams();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);
    const [newsData, setNewsData] = useState([]);
  
    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(1);
    };

    // useEffect(() => {
    //     window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    // }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, [language]);
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
          let paramsNews = {
              page:page,
              perPage:rowsPerPage,
              orderData:'id',
              orderDirection:'DESC',
              table:'news'
          }
          const news = await DynamicModuleManagementServices.getModule(paramsNews)
          if(news.data.length>0){
              setNewsData([...news.data])
              dispatch(setOtherNews([...news.data]))
              const total = Math.ceil(news.pagination['total'] / parseFloat(rowsPerPage));
              await setTotalPage(total);
              setTotalData(news.pagination['total'])
          }
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
        finishFetch()
      }
    }

    const getLimitedText = (originalText) => {
        let limitedText = originalText.substring(0, 300) + '...';
        return limitedText
    }
    const HtmlTextDisplay = (originalText) => {
      
        // Create a temporary div element to parse HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = `${originalText}`;
      
        // Extract text from paragraphs and list items with style="text-align:left;"
        let extractedText = '';
        const elements = tempDiv.querySelectorAll('p');
        elements.forEach(element => {
          extractedText += element.textContent.trim() + ' ';
        });
      
        // Trim extracted text to 100 characters
        const limitedText = extractedText.slice(0, 200);
      
        // Render the limited texta
        return (
          <div>
            <p data-translate-global-promo-and-news>{limitedText}...</p>
          </div>
        );
    };

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-global-promo-and-news]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-global-promo-and-news]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-global-promo-and-news]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };

    useEffect(() => {
        const handleScroll = () => {
            const element = cardRef.current;
            if (element) {
                const rect = element.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        
                if (rect.top <= windowHeight && rect.bottom >= 0) {
                    element.classList.add('scroll-anim-visible');
                }
                else {
                    element.classList.remove('scroll-anim-visible');
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box 
            className="backgroud-custom-1" 
            sx={{
                minHeight:'70vh',
                // backgroundColor:'#f5f5f5'
            }}
        >
            <Box py={5} sx={{display:isLoading?'block':'none'}}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <CircularProgress />
                </Container>
            </Box>
            <Box 
                py={5}
                sx={{display:isLoading?'none':'block'}}
            >
                <Box my={100} py={10} sx={{backgroundColor:'#f5f5f5'}}>
                    <Container maxWidth="xl">
                        <Box sx={{color:'#000'}}>
                            <Typography data-translate-global-promo-and-news gutterBottom variant="h3" component="div" textAlign={"left"}>
                                Promo Dan Berita
                            </Typography>
                        </Box>
                        {
                            newsData.length> 0 ?
                            <>
                                <Box
                                    ref={cardRef}
                                    className="scroll-anim"
                                    display={"flex"}
                                    flexDirection={'row'}
                                    flexWrap={'wrap'}
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                    }}
                                >
                                    {
                                        newsData.map((data) =>
                                            <>
                                                <Box
                                                    sx={{
                                                        width:{ xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' },
                                                    }}
                                                    p={1}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={'column'}
                                                        // justifyContent={'center'}
                                                        // alignItems={'stretch'}
                                                        justifyContent={'space-between'}
                                                        sx={{
                                                            backgroundColor:'#fff',
                                                            height:'100%',
                                                            borderRadius:2,
                                                        }}
                                                    >
                                                        <Box
                                                            display={"flex"}
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            sx={{
                                                                width:'100%',
                                                                borderTopLeftRadius:5,
                                                                borderTopRightRadius:5,
                                                            }}
                                                        >
                                                            <img 
                                                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.news_image_header}`}
                                                                style={{
                                                                    width:'100%',
                                                                    height:'150px',
                                                                    objectFit:'cover',
                                                                    borderTopLeftRadius:5,
                                                                    borderTopRightRadius:5,
                                                                }} 
                                                            />
                                                        </Box>
                                                        <Box
                                                            display={'flex'}
                                                            flexGrow={1}
                                                            flexDirection={'column'}
                                                            sx={{
                                                                width:'100%',
                                                            }}
                                                            p={2}
                                                        >
                                                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                                                {data.news_title}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                                {
                                                                    HtmlTextDisplay(data.news_content)
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width:'100%',
                                                            }}
                                                            p={2}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:'#000000',
                                                                    color:'#ffffff',
                                                                    borderRadius:1,
                                                                    cursor:'pointer',
                                                                }}
                                                                onClick={()=>{Navigate(`/promo-and-news/${encryptData(data.id)}`)}} 
                                                            >
                                                                <Typography data-translate-bisnis-detail variant="subtitle1" sx={{fontWeight:"bold"}}>Baca Selengkapnya</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    }
                                </Box>
                                <Box py={2}>
                                    <Pagination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={(event,page) => handleChangePage(event,page)} />
                                </Box>
                            </>
                            :
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{
                                    width:'100%',
                                    height:'200px'
                                }}
                            >
                                <Typography variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                    No Promo / News data available yet . . .
                                </Typography>
                            </Box>
                        }
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}

export default GlobalPromoAndNews;
  