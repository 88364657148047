import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom"
import {
    AdminPage,
    LoginPage,
} from "./pages"
import MainPage from './Main.js'

export default function MainRoutes(){
  const [isLogin] = useState(localStorage.getItem("login"))

  if(isLogin){
    return (
      <Routes>
        <Route path="/*" element={<MainPage />} />
        <Route path="/admin-page/*" element={<AdminPage />} />
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route path="/*" element={<MainPage />} />
        <Route path="/admin-page/*" element={<LoginPage />} />
      </Routes>
    )
  }
}