import axios from 'axios';
//main//
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
//material//
import {
    Box,
    Pagination,
    Paper,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
//services//

const StyledPageination = styled(Pagination)(({theme}) => ({
  '&& .Mui-selected' : {
    backgroundColor: '#e5e5e5',
    color:'#000000'
  },
}))

// const IDLE_TIMEOUT = 3 * 60 * 1000 
// 3 minutes in ms
const IDLE_TIMEOUT = 2000 

const API_KEY = 'AIzaSyAOLG5aVixlS3dOgR_IIybGCK4T1DXBlpM';
const URL = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;

function MainPageData() {
    const [userData, setUserData] = useState(null);
    const [isIdle, setIsIdle] = useState(false);
    const [lastActiveTime, setLastActiveTime] = useState(Date.now());
    const [lastIdleTime, setLastIdleTime] = useState(0);
    const [activeTime, setActiveTime] = useState(0);
  
    const onIdle = () => {
      console.log('User is idle');
      setIsIdle(true);
      const idleTime = Date.now()
      const time = Math.floor(idleTime - Number(lastActiveTime))
    //   const userTime = time - (IDLE_TIMEOUT - 300)
      const userTime = time - IDLE_TIMEOUT
      setLastIdleTime(idleTime)
      setActiveTime(userTime)
    };
  
    const onActive = () => {
      console.log('User is active');
      setIsIdle(false);
      setLastActiveTime(Date.now())
    };
  
    useIdleTimer({
      timeout: IDLE_TIMEOUT,
      onIdle,
      onActive,
    });

    useEffect(()=>{
        let getUserData = localStorage.getItem('user')
        setUserData(JSON.parse(getUserData))
    },[])

    const translateText = async () => {
        try {
            const arrayOfText = ['halo selamat datang di prosperity website','Kami senang bisa berkenalan dengan anda']
            const response = await axios.post(URL, {
                q: arrayOfText,
                target: 'en',
            });
            console.warn('Success', response.data);
            let loopOfTranslation = response.data.data.translations
            for (const text of loopOfTranslation) {
                console.warn('translatedText', text);
            }
        } catch (error) {
            console.error('Error translating text:', error);
        }
    };
    
    return (
        <>
            <Box>
                <Paper
                    elevation={3}
                    sx={{
                        padding:2,
                        paddingBottom:2,
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'flex-start',
                        flexDirection:'column'
                    }}
                >
                    <Box>
                        <Typography variant="h4">Welcome to Prosperity admin panel</Typography>
                        <Typography variant="h4" sx={{fontWeight:'bold'}}>{userData?.user_name}</Typography>
                    </Box>
                </Paper>
            </Box>
        </>
    );
}

export default MainPageData;
