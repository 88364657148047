import React, { useEffect, useState, useRef } from 'react';
import { 
    Box,
    Typography,
    TextField,
    Button,
} from '@mui/material';
//services//
import AuthServices from '../../../services/Auth.service'
import './LoginPage.css';

function LoginPage() {
    const [currentStep, setCurrentStep] = useState(0);
    const [direction, setDirection] = useState(null);
    const [email, setEmail] = useState(null);
    const [otp, setOTP] = useState(null);

    const steps = ['login','verify'];

    const nextStep = () => {
      setDirection('right');
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    };
  
    const prevStep = () => {
      setDirection('left');
      setCurrentStep((prev) => Math.max(prev - 1, 0));
    };
  
    const getAnimationClass = () => {
      if (direction === 'right') {
        return 'slide-right-enter';
      } else if (direction === 'left') {
        return 'slide-left-enter';
      }
      return '';
    };

    const login = async () => {
        if(email==null || email==''){
            alert(`Email cannot be empty`)
        } else {
            try{
                let params = {
                    email:email
                }
                let userData = await AuthServices.getOTP(params)
                if(userData.data.error == 0){
                    nextStep()
                } else {
                    alert(`${userData.data.message}`)
                }
            } catch(error){
                alert(`There is and error`)
                console.warn(`There is and error`, error);
            }
        }
    }

    const verify = async () => {
        if(otp==null || otp==''){
            alert(`OTP cannot be empty`)
        } else {
            try{
                let params = {
                    email:email,
                    code:otp
                }
                let userData = await AuthServices.verifyOTP(params)
                localStorage.setItem("login","true")
                localStorage.setItem("user",JSON.stringify(userData.data[0]))
                window.location.reload()
            } catch(error){
                if(error.response.status && error.response.status == 400){
                    alert(`${error.response.data['ErrorMessage']}`)
                } else {
                    alert(`There is and error`)
                    console.warn(`There is and error`, error);
                }
            }
        }
    }

    return (
        <Box
            sx={{backgroundColor:'#dedede',minHeight:'100vh'}}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Box
                sx={{
                    minWidth:'500px',
                    backgroundColor:'#E2E8F0',
                    borderRadius:2,
                    boxShadow:'0px 2px 2px rgba(0,0,0,0.3)',
                    p:4
                }}
                display={"flex"}
                flexDirection={"column"}
            >
                <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                >
                    <Box>
                        <Typography sx={{fontSize:'26px',fontWeight:'700'}}>Login Page</Typography>
                    </Box>
                </Box>
                {
                    currentStep == 0 &&
                    <Box
                        // className={`form-step ${currentStep === 0 ? 'form-step-active' : ''} ${currentStep === 0 ? getAnimationClass() : ''}`}
                    >
                        <Box
                            sx={{
                                pt:2,pb:1
                            }}
                        >
                            <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#475569'}}>Please enter your email.</Typography>
                        </Box>
                        <Box
                            sx={{
                                pt:1,pb:2
                            }}
                        >
                            <Box>
                                <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#1E293B'}}>Email</Typography>
                            </Box>
                            <Box>
                                <TextField 
                                    variant="outlined" 
                                    size="small" 
                                    sx={{width:'100%',backgroundColor:'#fff'}} 
                                    value={email}
                                    onChange={(value)=>{setEmail(value.target.value)}}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                pt:2
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={()=>login()}
                                color="primary"
                            >
                                Get OTP
                            </Button>
                        </Box>
                    </Box>
                }
                {
                    currentStep == 1 &&
                    <Box
                        // className={`form-step ${currentStep === 1? 'form-step-active' : ''} ${currentStep === 1 ? getAnimationClass() : ''}`}
                    >
                        <Box
                            sx={{
                                pt:2,pb:1
                            }}
                        >
                            <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#475569'}}>We already send the OTP to your email.</Typography>
                        </Box>
                        <Box
                            sx={{
                                pt:1,pb:2
                            }}
                        >
                            <Box>
                                <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#1E293B'}}>OTP</Typography>
                            </Box>
                            <Box>
                                <TextField 
                                    variant="outlined" 
                                    size="small" 
                                    sx={{width:'100%',backgroundColor:'#fff'}} 
                                    value={otp}
                                    onChange={(value)=>{setOTP(value.target.value)}}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                pt:2
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={()=>verify()}
                                color="primary"
                            >
                                Login
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default LoginPage;
