import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.defaults.withCredentials = true;
// instance.interceptors.request.use(
//   async (config) => {
//     config.headers = {
//       "Content-Type": "application/json",
//     };
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const response = await getAxios('/auth/refresh-token',);
      if (response && !response.success) {
        alert.error("Token is expired, please try login again", 7000);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      return instance(originalRequest);
    } else if (
      error &&
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      localStorage.removeItem('login')
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const forceLogout = () => {
  document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  document.cookie = "id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  document.cookie = "roles=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  document.cookie = "name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  document.cookie = "phone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=localhost;";
  window.location.reload();
};

const getAxios = async (url, params) => {
  return instance
    .get(url, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        if (error.response.data && error.response.data.message && error.response.data.message.includes('Unauthorized!')) {
          // TODO: need to popup that current session has expired
          forceLogout();
        }
      }
      throw error;
    });
};

const postAxios = async (url, data, params) => {
  return instance
    .post(url, data, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        if (error.response.data && error.response.data.message && error.response.data.message.includes('Unauthorized!')) {
          // TODO: need to popup that current session has expired
          forceLogout();
        }
      }
      throw error;
    });
};

const postFromAxios = async (url, data, params) => {
  return instance
    .post(url, data, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        if (error.response.data && error.response.data.message && error.response.data.message.includes('Unauthorized!')) {
          // TODO: need to popup that current session has expired
          forceLogout();
        }
      }
      throw error;
    });
};

const postAxiosProgress = async (url, data, params) => {
  return instance
    .post(url, data, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        if (error.response.data && error.response.data.message && error.response.data.message.includes('Unauthorized!')) {
          // TODO: need to popup that current session has expired
          forceLogout();
        }
      }
      throw error;
    });
};

const putAxios = async (url, data, params) => {
  return instance
    .put(url, data, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      }
      throw error;
    });
};

const patchAxios = async (url, data, params) => {
  return instance
    .patch(url, data, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      }
      throw error;
    });
};

const deleteAxios = async (url, data, params) => {
  return instance
    .delete(url, data, params)
    .then((response) => (response.data ? response.data : response))
    .catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      }
      throw error;
    });
};

const api = { getAxios, postAxios, postFromAxios, postAxiosProgress, putAxios, patchAxios, deleteAxios };

export default api;
