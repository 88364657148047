import React from "react"
import { Routes, Route } from "react-router-dom"
import MainPage from "../pages/AdminPage/Main/MainPage"
import BisnisGroupPage from "../pages/AdminPage/BisnisGroup/BisnisGroupPage"
import BisnisPage from "../pages/AdminPage/Bisnis/BisnisPage"
import PromoNewsPage from "../pages/AdminPage/PromoNews/PromoNewsPage"
import CarouselPage from "../pages/AdminPage/Carousel/CarouselPage"
import UserPage from "../pages/AdminPage/User/UserPage"
import ProductCategoryPage from "../pages/AdminPage/ProductCategory/ProductCategoryPage"
import ProductPage from "../pages/AdminPage/Product/ProductPage"
import CareerPage from "../pages/AdminPage/Career/CareerPage"
import ContactPage from "../pages/AdminPage/Contact/ContactPage"
import CompanyPage from "../pages/AdminPage/Company/CompanyPage"
import SocialMediaPage from "../pages/AdminPage/SocialMedia/SocialMediaPage"

export default function MainRoutes(){
    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/carousel" element={<CarouselPage />} />
            <Route path="/business-category" element={<BisnisGroupPage />} />
            <Route path="/business" element={<BisnisPage />} />
            <Route path="/product-category" element={<ProductCategoryPage />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/promo-and-news" element={<PromoNewsPage />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/social-media" element={<SocialMediaPage />} />
        </Routes>
    )
}