import React, { useEffect, useState } from 'react';
import { translateText } from './services/translateService';
import { useTranslation } from './contex/TranslationContext';
import { useLocation } from 'react-router-dom';

import './App.css';
import 'rsuite/dist/rsuite.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavHeader from './components/NavHeader/NavHeader';
import Footer from './components/Footer/Footer';
import AppRoute from './routes/index'
import {
  Box
} from '@mui/material';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <header className="App-header">
        <NavHeader />
      </header>
      <Box 
      // sx={{mt:'5vh'}}
      >
        <AppRoute />
      </Box>
      <Footer />
    </div>
  );
}

export default App;
