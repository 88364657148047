import axios from "axios";
import api from "../utils/api";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class ModuleManagement {
  getModule = async (params) => {
    return await api.getAxios(
      "/prosperity-dynamic-module",
      { params },
      { cancelToken: source.token }
    );
  };

  createModule = async (params) => {
    delete params.id
    params = {
      ...params
    }
    return await api.postAxios(
      "/prosperity-dynamic-module",
      params,
      { cancelToken: source.token }
    );
  };

  updateModule = async (params) => {
    let serviceId = params.id
    delete params.id
    return await api.putAxios(
      `/prosperity-dynamic-module/${serviceId}`,
      params,
      { cancelToken: source.token }
    );
  };

  createFormModule = async (params) => {
    // delete params.id
    // params = {
    //   ...params
    // }
    return await api.postAxios(
      "/prosperity-dynamic-module/form",
      params,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        cancelToken: source.token 
      }
    );
  };

  updateFormModule = async (params,serviceId) => {
    return await api.putAxios(
      `/prosperity-dynamic-module/form/${serviceId}`,
      params,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        cancelToken: source.token 
      }
    );
  };

  deleteModule = async (params) => {
    let serviceId = params.id
    delete params.id
    return await api.deleteAxios(
      `/prosperity-dynamic-module/${serviceId}`, 
      { },
      { cancelToken: source.token }
    );
  };
}

export default new ModuleManagement();
