import React, {useEffect,useState} from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import DOMPurify from 'dompurify';
import {
    Container,
    Box,
    Grid,
    Typography,
    CircularProgress,
} from '@mui/material';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function AboutUsPage() {
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, [language]);
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              id:encryptData(1),
              orderData:'id',
              orderDirection:'ASC',
              table:'company'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            finishFetch()
        }
    }

    const addDataTranslateAttribute = (htmlContent) => {
        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
      
        // Function to recursively add data-translate-about-us to smallest elements
        function addDataTranslateToSmallestElements(element) {
          // If the element has no children, add the data-translate-about-us attribute
          if (!element.children.length) {
            element.setAttribute('data-translate-about-us', '');
          } else {
            // Otherwise, recurse on children
            Array.from(element.children).forEach(addDataTranslateToSmallestElements);
          }
        }
      
        // Get the body element and start the recursion
        addDataTranslateToSmallestElements(doc.body);
      
        // Serialize the modified DOM back to a string
        return doc.body.innerHTML;
    };

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        const returnHTML = addDataTranslateAttribute(sanitizedHtmlString)
        return returnHTML
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-about-us]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-about-us]');
                elements.forEach((el, idx) => {
                    console.warn(`check originalText`, originalText[idx]);
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-about-us]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            <Box py={5} sx={{display:isLoading?'block':'none'}}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <CircularProgress />
                </Container>
            </Box>
            <Box py={5} sx={{display:isLoading?'none':'block'}}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <Box sx={{color:'#000'}}>
                        <Typography data-translate-about-us gutterBottom variant="h3" component="div" textAlign={"left"}>
                            Tentang Kami
                        </Typography>
                    </Box>
                    <Box p={10} sx={{backgroundColor:'#fff',minHeight:'50vh'}}>
                        <div dangerouslySetInnerHTML={{ __html: renderHTML(pageData[0]?.company_history) }} />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default AboutUsPage;
  