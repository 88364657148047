import React, { useEffect, useState, useRef, Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service';
//css
import './PromoNewsPage.css';

const maxFileSize = 10000000;
const filetype = [".JPG",".jpg",".JPEG",".jpeg",".PNG",".png",".SVG",".svg"]

function MainPageModal({modalHandle,modalData,modalRefresh,masterCategoryData}) {
    const toaster = useToaster();
    const [detailData, setDetailData] = useState(null);
  
    const [tempImage1, setTempImage1] = useState([]);
    const [imageInfo1, setImageInfo1] = useState([]);
    //-------//
    const onFileCheck1 = (data) => {
        if(data.length > 1){
            alert("You can only upload 1 file at a time", 5000);
        }else{
            if(data.length) {
                let valid = false;
                let filename = data[0].blobFile.name;
                filetype.forEach((type, index) =>{
                    console.log(type)
                    if(filename.toLowerCase().includes(type)){
                        valid = true
                    }
                })
                if(valid){
                    console.log(data[0]);
                    if(data[0].blobFile.size > maxFileSize){
                        alert("File Exceeding Max Limit of 10mb", 5000);
                    }else{
                        setTempImage1(data)
                        const objectUrl = URL.createObjectURL(data[0].blobFile)
                        setImageInfo1([{
                            url:objectUrl,
                            name:data[0].name
                        }])
                    }
                }else{
                    alert("Please upload a correct file extension!", 5000);
                }
            }
        }
    }
    const clearIconData1 = () => {
        setTempImage1([])
        setImageInfo1([])
        URL.revokeObjectURL(imageInfo1)
    }
    //-----//

    //-----//
    const [editorState, setEditorState] = useState(()=>EditorState.createEmpty());
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const handleEditorState = (value) => {
        setEditorState(value)
        setDetailData({...detailData,news_content:draftToHtml(rawContentState)})
    };
    //-----//

    useEffect(()=>{
        if(modalData?.id){
            setDetailData({
                id: modalData.id ? modalData.id : null,
                news_title: modalData.news_title ? modalData.news_title : null,
                news_content: modalData.news_content ? modalData.news_content : null,
                bisnis_id: modalData.bisnis_id ? modalData.bisnis_id : null,
                news_image_header: modalData.news_image_header ? modalData.news_image_header : null,
            })
            if(modalData.news_content === null || modalData.news_content === ''){
                setEditorState(EditorState.createEmpty())
            } else {
                const contentBlock = htmlToDraft(`${modalData.news_content}`)
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                setEditorState(EditorState.createWithContent(contentState))
            }
            if(modalData.news_image_header){
                setImageInfo1([{
                  url:`${process.env.REACT_APP_API_URL}/prosperity-file/${modalData.news_image_header}`,
                  name:modalData.news_image_header
                }])
            }
        } else {
            setDetailData({
                id: null,
                news_title: null,
                news_content: null,
                bisnis_id: null,
                news_image_header: null,
            })
        }
    },[modalData])

    const handleEdit = (key,data) => {
        let tempData = {...detailData}
        tempData[`${key}`] = data
        setDetailData({...tempData})
    };

    const saveUpdatedData = async () => {
        try{
            let ModuleParams = {
                ...detailData,
                table:'news'
            }
            const params = new FormData()
            const keys = Object.keys(ModuleParams)
            keys.forEach((key,index) => {
                params.append(`${key}`, ModuleParams[key])
            });
            let imageList = []
            if(tempImage1.length > 0){
              const blobFile1 = new File([tempImage1[0].blobFile], tempImage1[0].name, tempImage1[0].type)
              params.append("files", blobFile1)
              imageList.push({
                itemName:"news_image_header"
              })
            }
            if(imageList.length>0){
                params.append("image_list", JSON.stringify(imageList))
            }
            if(detailData?.id){
                await DynamicModuleManagementServices.updateFormModule(params,detailData?.id)
                modalRefresh()
            } else {
                await DynamicModuleManagementServices.createFormModule(params)
                modalRefresh()
            }
        } catch(error){
            modalRefresh()
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        }
    }

    return (
        <Modal
          open={true}
        >
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    height:'100vh',
                    width:'100vw'
                }}
            >
                <Container maxWidth="xl">
                    <Paper>
                        <Box p={2}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box>
                                    <Typography>{modalData ? `Edit ` : `Add `} Promo / News</Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="close" onClick={modalHandle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                p={1}
                                sx={{
                                    maxHeight:'80vh',
                                    overflow:'auto'
                                }}
                            >
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <select
                                                    style={{
                                                        height:'40px',
                                                        width:'100%',
                                                        border:'1px solid #dbdbdb',
                                                        borderRadius:'3px'
                                                    }}
                                                    onChange={(value)=>{handleEdit(`bisnis_id`,value.target.value)}}
                                                    value={detailData?.bisnis_id}
                                                >
                                                    <option value={''}>Please choose a business</option>
                                                    <option value={0}>Prosperity Group</option>
                                                    {
                                                        masterCategoryData&&masterCategoryData.length>0&&masterCategoryData.map((data)=>
                                                            <option value={data.value}>{data.label}</option>
                                                        )
                                                    }
                                                </select>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Promo / News Title"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={detailData?.news_title?detailData.news_title:''}
                                                    onChange={(value)=>handleEdit(`news_title`,value.target.value)}
                                                    placeholder="Promo / News Title"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                flexDirection={'column'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <Box>
                                                    <Typography>Promo / News Content</Typography>
                                                </Box>
                                                <Box sx={{
                                                    border:'1px solid #000',
                                                    width:'100%',
                                                    minHeight:'250px'
                                                }}>
                                                    <Editor 
                                                        editorState={editorState}
                                                        onEditorStateChange={(state)=> handleEditorState(state)}
                                                     />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography>Promo / News Image Header</Typography>
                                            </Box>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                {
                                                    imageInfo1.length>0?
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'column'}
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        sx={{
                                                            width:'100%'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width:'50%'
                                                            }}
                                                        >
                                                            <img src={imageInfo1[0].url} style={{width:'100%'}} />
                                                        </Box>
                                                        <Box
                                                            display={'flex'}
                                                            flexDirection={'row'}
                                                        >
                                                            <Box>
                                                                <Typography style={{color:'#ff0000'}}>{imageInfo1[0].name}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography pl={2} style={{cursor:'pointer'}} onClick={() => clearIconData1()}>clear</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Uploader 
                                                        fileListVisible={false}
                                                        value={tempImage1}
                                                        autoUpload={false}
                                                        onChange={onFileCheck1}
                                                        accept=".JPG,.jpg,.JPEG,.jpeg,PNG,.png,.SVG,.svg"
                                                        multiple={false}
                                                        disabled={imageInfo1.length>0 ? true : false}
                                                        fileList={tempImage1}
                                                        draggable
                                                        style={{width:'100%'}}
                                                    >
                                                        <div style={{ height: 100, width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <span>Click or Drag files to this area to upload Promo / News Image Header</span>
                                                        </div>
                                                    </Uploader>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={modalHandle}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={()=>saveUpdatedData()}
                                        color="success"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
}

export default MainPageModal;
