import React, {useEffect,useState} from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import {
    Container,
    Box,
    Grid,
    Typography,
    CircularProgress,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import Map from '../../components/Map/Map';
import DOMPurify from 'dompurify';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function ContactUsPage() {
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);
    const [detailData, setPageDetailData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, [language]);
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              id:encryptData(1),
              orderData:'id',
              orderDirection:'ASC',
              table:'contact'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
            let paramsNews = {
                contact_id:1,
                table:'contact_item'
            }
            const detailDatas = await DynamicModuleManagementServices.getModule(paramsNews)
            if(detailDatas.data.length>0){
                setPageDetailData([...detailDatas.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            finishFetch()
        }
    }

    const addDataTranslateAttribute = (htmlContent) => {
        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
      
        // Function to recursively add data-translate-contact-us to smallest elements
        function addDataTranslateToSmallestElements(element) {
          // If the element has no children, add the data-translate-contact-us attribute
          if (!element.children.length) {
            element.setAttribute('data-translate-contact-us', '');
          } else {
            // Otherwise, recurse on children
            Array.from(element.children).forEach(addDataTranslateToSmallestElements);
          }
        }
      
        // Get the body element and start the recursion
        addDataTranslateToSmallestElements(doc.body);
      
        // Serialize the modified DOM back to a string
        return doc.body.innerHTML;
    };

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        const returnHTML = addDataTranslateAttribute(sanitizedHtmlString)
        return returnHTML
    }

    const getCoordinate = (value) => {
        const centerCoordinate = {
            lat: 0,
            lng: 0
        }
        let rawCoordinate = value.split(',')
        if(rawCoordinate.length>0){
            centerCoordinate.lat = parseFloat(rawCoordinate[0])
            centerCoordinate.lng = parseFloat(rawCoordinate[1])
        }
        return centerCoordinate
    }

    const getDetailItemIcon = (value) => {
        if(value == 'phone'){
            return <PhoneIcon sx={{fontSize: 40}} />
        } else if(value == 'email'){
            return <EmailIcon sx={{fontSize: 40}} />
        } else if(value == 'address'){
            return <PlaceIcon sx={{fontSize: 40}} />
        } else {
            return ''
        }
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-contact-us]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-contact-us]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-contact-us]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            <Box py={5} sx={{display:isLoading?'block':'none'}}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <CircularProgress />
                </Container>
            </Box>
            <Box py={5} sx={{display:isLoading?'none':'block'}}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <Box sx={{color:'#000'}}>
                        <Typography data-translate-contact-us gutterBottom variant="h3" component="div" textAlign={"left"}>
                            Kontak Kami
                        </Typography>
                    </Box>
                    <Box p={10} sx={{backgroundColor:'#fff'}}>
                        {
                            pageData.length>0&&
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Box>
                                            <Typography data-translate-contact-us gutterBottom variant="h4" component="div" textAlign={"left"}>
                                                Detail Kontak
                                            </Typography>
                                            <div dangerouslySetInnerHTML={{ __html: renderHTML(pageData[0]?.contact_detail) }} />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            py={2}
                                        >
                                            {
                                                detailData&&detailData.length>0&&detailData.map((item,index) =>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        py={1}
                                                        key={index}
                                                    >
                                                        <Box
                                                            display={"flex"}
                                                            alignItems={'center'}
                                                            sx={{height:'100%'}}
                                                            mr={3}
                                                        >
                                                            {getDetailItemIcon(item.contact_item_type)}
                                                        </Box>
                                                        <Box>
                                                            <Box>
                                                                <Typography data-translate-contact-us variant="subtitle1" color="text.secondary" textAlign={"left"}>
                                                                    {item.contact_item_title}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography variant="subtitle1" color="text.secondary" textAlign={"left"}>
                                                                    {item.contact_item_details}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                        sx={{height:'100%',width:'100%'}}
                                    >
                                        <Map center={getCoordinate(pageData[0]?.contact_coordinate)} />
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default ContactUsPage;
  