import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from '../../contex/TranslationContext';
import DOMPurify from 'dompurify';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";

function MainPageModal({modalHandle,modalData}) {
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const toaster = useToaster();
    const [detailData, setDetailData] = useState(null);

    useEffect(()=>{
        console.warn(`check modalData`, modalData);
        if(modalData!==null){
            setIsLoading(true)
            setDetailData(modalData)
            finishFetch()
        }
    },[modalData,detailData,language])

    const addDataTranslateAttribute = (htmlContent) => {
        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
      
        // Function to recursively add data-translate-product-detail to smallest elements
        function addDataTranslateToSmallestElements(element) {
          // If the element has no children, add the data-translate-product-detail attribute
          if (!element.children.length) {
            element.setAttribute('data-translate-product-detail', '');
          } else {
            // Otherwise, recurse on children
            Array.from(element.children).forEach(addDataTranslateToSmallestElements);
          }
        }
      
        // Get the body element and start the recursion
        addDataTranslateToSmallestElements(doc.body);
      
        // Serialize the modified DOM back to a string
        return doc.body.innerHTML;
    };

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        const returnHTML = addDataTranslateAttribute(sanitizedHtmlString)
        return returnHTML
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-product-detail]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-product-detail]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-product-detail]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };

    return (
        <Modal
          open={true}
        >
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    height:'100vh',
                    width:'100vw',
                }}
            >
                <Container maxWidth="lg">
                    <Paper>
                        <Box p={2}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                        {detailData?.product_name}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="close" onClick={modalHandle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box 
                                display={'flex'}
                                flexDirection={'row'}
                                p={1}
                                sx={{display:isLoading?'flex':'none'}}
                            >
                                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                                    <CircularProgress />
                                </Container>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                p={1}
                                sx={{display:isLoading?'none':'flex'}}
                            >
                                <Box
                                    display={"flex"}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    sx={{
                                        width:'40%',
                                    }}
                                >
                                    <img 
                                        src={`${process.env.REACT_APP_API_URL}/prosperity-file/${detailData?.product_image}`}
                                        style={{
                                            width:'100%',
                                            objectFit:'cover',
                                        }} 
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        height:'50vh',
                                        width:'60%',
                                        overflow:'auto',
                                        padding:5
                                    }}
                                    py={1}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: renderHTML(detailData?.product_description) }} />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
}

export default MainPageModal;
