import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";                 // add this

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureAppStore from './redux/store'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material';

import { TranslationProvider } from './contex/TranslationContext';

const persistor = persistStore(configureAppStore)

render(
  <React.StrictMode>
    <Provider store={configureAppStore}>
      <PersistGate loading={<h1>Loading . . .</h1>} persistor={persistor}>
        <BrowserRouter basename={'/'}>
          <TranslationProvider>
            <App />
          </TranslationProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
