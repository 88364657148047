import React, {useEffect,useState} from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Typography,
    Pagination,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import Hero from '../../components/HeroSlider/Hero';
import HeroRsuite from '../../components/HeroRsuite/HeroRsuite';
import Section1 from '../../components/Section1/Section1';
import Section2 from '../../components/Section2/Section2';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
import food_1 from '../../assets/images/food_png/1.png';
import food_2 from '../../assets/images/food_png/2.png';
import food_3 from '../../assets/images/food_png/3.png';
import img_1 from '../../assets/images/fnb/food_1.png';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
//REDUX//
import { useDispatch } from "react-redux";
import { setOtherNews } from "../../redux/actions/app.action";
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
import ProductGaleriModal from './ProductGaleriModal';

function ProductGaleri() {
    let { id } = useParams();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
  
    const [pageData, setPageData] = useState([]);
    const [contentData, setContentData] = useState([]);
  
    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(1);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        if(id){
          getModuleData(id)
        }
    }, [id,language]);

    const modalHandleOpen = () => {
        setModalOpen(!modalOpen)
    };

    const modalHandleClose = () => {
        setModalData(null)
        setModalOpen(!modalOpen)
    };

    const openDetail = (row) => {
        setModalData({...row})
        setModalOpen(!modalOpen)
    };
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
        let params = {
            id:id,
            orderData:'id',
            orderDirection:'ASC',
            table:'product_category'
        }
        const data = await DynamicModuleManagementServices.getModule(params)
        if(data.data.length>0){
            setPageData([...data.data])
        }
          let paramsNews = {
              page:page,
              perPage:rowsPerPage,
              orderData:'id',
              orderDirection:'DESC',
              product_category_id:decryptData(id),
              table:'product'
          }
          const product = await DynamicModuleManagementServices.getModule(paramsNews)
          if(product.data.length>0){
              setContentData([...product.data])
              dispatch(setOtherNews([...product.data]))
              const total = Math.ceil(product.pagination['total'] / parseFloat(rowsPerPage));
              await setTotalPage(total);
              setTotalData(product.pagination['total'])
          }
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
        finishFetch()
      }
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-product-category]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-product-category]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-product-category]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };

    return (
        <Box sx={{minHeight:'70vh',backgroundColor:'#f5f5f5'}}>
            <Box 
                py={5}
            >
                <Box>
                    <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                        <Box sx={{color:'#000'}}>
                            {
                                pageData.length>0&&
                                <Typography data-translate-product-category gutterBottom variant="h3" component="div" textAlign={"left"}>
                                {pageData[0].product_category_name} Produk
                                </Typography>
                            }
                        </Box>
                        {
                            contentData.length> 0 ?
                            <>
                                <Box
                                    display={"flex"}
                                    flexDirection={'row'}
                                    flexWrap={'wrap'}
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                    }}
                                >
                                    {
                                        contentData.map((data) =>
                                            <>
                                                <Box
                                                    sx={{
                                                        width:{ xs: '100%', sm: '100%', md: '20%', lg: '20%', xl: '20%' },
                                                    }}
                                                    p={1}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={'column'}
                                                        // justifyContent={'center'}
                                                        alignItems={'stretch'}
                                                        sx={{
                                                            backgroundColor:'#fff',
                                                            borderRadius:2
                                                        }}
                                                    >
                                                        <Box
                                                            display={"flex"}
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            sx={{
                                                                width:'100%',
                                                            }}
                                                        >
                                                            <img 
                                                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.product_image}`}
                                                                style={{
                                                                    width:'100%',
                                                                    objectFit:'cover',
                                                                }} 
                                                            />
                                                        </Box>
                                                        <Box
                                                            display={"flex"}
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            flexDirection={'column'}
                                                            sx={{
                                                                width:'100%',
                                                            }}
                                                            p={2}
                                                        >
                                                            <Typography data-translate-product-category gutterBottom variant="h5" component="div" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                                                {data.product_name}
                                                            </Typography>
                                                            {/* <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                                {
                                                                    HtmlTextDisplay(data.product_description)
                                                                }
                                                            </Typography> */}
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:'#000000',
                                                                    color:'#ffffff',
                                                                    width:'auto',
                                                                    borderRadius:5,
                                                                    cursor:'pointer'
                                                                }}
                                                                px={3}
                                                                onClick={()=> openDetail(data)} 
                                                            >
                                                                <Typography data-translate-product-category variant="subtitle1" sx={{fontWeight:"bold"}}>Lihat Detail</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    }
                                </Box>
                                <Box py={2}>
                                    <Pagination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={(event,page) => handleChangePage(event,page)} />
                                </Box>
                            </>
                            :
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{
                                    width:'100%',
                                    height:'200px'
                                }}
                            >
                                <Typography data-translate-product-category variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                    Tidak ada data produk untuk saat ini . . .
                                </Typography>
                            </Box>
                        }
                    </Container>
                </Box>
            </Box>
            {
                modalOpen &&
                <ProductGaleriModal 
                    modalHandle={modalHandleClose}
                    modalData={modalData}
                />
            }
        </Box>
    );
}

export default ProductGaleri;
  