import React, {useEffect,useState} from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import DOMPurify from 'dompurify';
import {
    Container,
    Box,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function AboutUsPage() {
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, [language]);
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
          let params = {
              orderData:'id',
              orderDirection:'DESC',
              table:'career'
          }
          const result = await DynamicModuleManagementServices.getModule(params)
          setPageData([...result.data])
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
        finishFetch()
      }
    }

    const addDataTranslateAttribute = (htmlContent) => {
        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
      
        // Function to recursively add data-translate-karir to smallest elements
        function addDataTranslateToSmallestElements(element) {
          // If the element has no children, add the data-translate-karir attribute
          if (!element.children.length) {
            element.setAttribute('data-translate-karir', '');
          } else {
            // Otherwise, recurse on children
            Array.from(element.children).forEach(addDataTranslateToSmallestElements);
          }
        }
      
        // Get the body element and start the recursion
        addDataTranslateToSmallestElements(doc.body);
      
        // Serialize the modified DOM back to a string
        return doc.body.innerHTML;
    };

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        const returnHTML = addDataTranslateAttribute(sanitizedHtmlString)
        return returnHTML
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-karir]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-karir]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-karir]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            {/* <Box sx={{position:'absolute',width:'100%'}}>
                <img src={bg_1} height={'350px'} width={'100%'} style={{objectFit:'cover'}}/>
            </Box> */}
            <Box py={5}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <Box sx={{color:'#000'}}>
                        <Typography data-translate-karir gutterBottom variant="h3" component="div" textAlign={"left"}>
                            Daftar Karir
                        </Typography>
                    </Box>
                    <Box p={{ xs: 1, sm: 1, md: 10, lg: 10, xl: 10 }} sx={{backgroundColor:'#fff',minHeight:'50vh'}}>
                        <Box py={2}>
                            <Typography data-translate-karir gutterBottom variant="h6" component="div" textAlign={"center"}>
                                Posisi terbuka
                            </Typography>
                            <Typography data-translate-karir gutterBottom variant="h4" component="div" textAlign={"center"}>
                                Lowongan kerja saat ini
                            </Typography>
                        </Box>
                        <Box>
                            {
                                pageData&&pageData.length>0&&pageData.map((data) => 
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <Box>
                                                    <Typography data-translate-karir gutterBottom variant="h5" component="div" textAlign={"left"}>
                                                        {data.career_title}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography data-translate-karir gutterBottom variant="body2" component="div" textAlign={"left"}>
                                                        {data.career_description}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div dangerouslySetInnerHTML={{ __html: renderHTML(data.career_detail) }} />
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default AboutUsPage;
  