import React, { useEffect, useState } from 'react';
import {
    Breadcrumbs,
} from "../../components"
import { 
    Box,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Container,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@rsuite/icons/Menu';

function Header({onSidebar}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userData, setUserData] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(()=>{
        let getUserData = localStorage.getItem('user')
        setUserData(JSON.parse(getUserData))
    },[])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        localStorage.removeItem("login")
        localStorage.removeItem("user")
        window.location.reload()
    }

    return (
        <Box
            display={"flex"}
            flexDirection={'row'}
            justifyContent={"space-between"}
            sx={{height:'100%'}}
            alignItems={"center"}
        >   
            <Box
                display={"flex"}
                flexDirection={'row'}
                alignItems={"center"}
                sx={{height:'100%'}}
            > 
                <Box>
                    <MenuIcon 
                        style={{fontSize:'2em',cursor:'pointer'}} 
                        onClick={onSidebar}
                    />
                </Box>
            </Box>
            <Box
                display={"flex"}
                flexDirection={'row'}
                alignItems={"center"}
                sx={{height:'100%'}}
            >
                <Box
                    sx={{pr:2}}
                >
                    <Typography>{userData?.user_name}</Typography>
                </Box>
                <Box 
                    id={'basic-button'} 
                    sx={{cursor:'pointer'}} 
                    onClick={handleClick}
                >
                    <ExpandMoreIcon />
                </Box>
                <Menu
                anchorEl={anchorEl}
                id="basic-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    );
}

export default Header;
