import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service';
import { nlNL } from 'rsuite/esm/locales';

function MainPageModal({modalHandle,modalData,modalRefresh}) {
    const toaster = useToaster();
    const [detailData, setDetailData] = useState(null);

    useEffect(()=>{
        if(modalData?.id){
            setDetailData({
                id: modalData.id ? modalData.id : null,
                nama_bisnis_category: modalData.nama_bisnis_category ? modalData.nama_bisnis_category : null
            })
        } else {
            setDetailData({
                id: null,
                nama_bisnis_category: null
            })
        }
    },[modalData])

    const handleEdit = (key,data) => {
        let tempData = {...detailData}
        tempData[`${key}`] = data
        setDetailData({...tempData})
    };

    const saveUpdatedData = async () => {
        try{
            let ModuleParams = {
                ...detailData,
                table:'bisnis_category'
            }
            if(detailData?.id){
                await DynamicModuleManagementServices.updateModule(ModuleParams)
                modalRefresh()
            } else {
                await DynamicModuleManagementServices.createModule(ModuleParams)
                modalRefresh()
            }
        } catch(error){
            modalRefresh()
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        }
    }

    return (
        <Modal
          open={true}
        >
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    height:'100vh',
                    width:'100vw'
                }}
            >
                <Container maxWidth="sm">
                    <Paper>
                        <Box p={2}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box>
                                    <Typography>{modalData ? `Edit ` : `Add `} Business Category</Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="close" onClick={modalHandle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                p={1}
                            >
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Business Category Name"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={detailData?.nama_bisnis_category?detailData.nama_bisnis_category:''}
                                                    onChange={(value)=>handleEdit(`nama_bisnis_category`,value.target.value)}
                                                    placeholder="Business Category Name"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={modalHandle}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={()=>saveUpdatedData()}
                                        color="success"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
}

export default MainPageModal;
