import React, { useEffect, useState, useRef, Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service';
//css
import './CareerPage.css';

const maxFileSize = 10000000;
const filetype = [".JPG",".jpg",".JPEG",".jpeg",".PNG",".png",".SVG",".svg"]

function MainPageModal({modalHandle,modalData,modalRefresh,masterCategoryData}) {
    const toaster = useToaster();
    const [detailData, setDetailData] = useState(null);
    
    //-----//
    const [editorState, setEditorState] = useState(()=>EditorState.createEmpty());
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const handleEditorState = (value) => {
        setEditorState(value)
        setDetailData({...detailData,career_detail:draftToHtml(rawContentState)})
    };
    //-----//

    useEffect(()=>{
        if(modalData?.id){
            setDetailData({
                id: modalData.id ? modalData.id : null,
                career_title: modalData.career_title ? modalData.career_title : null,
                career_description: modalData.career_description ? modalData.career_description : null,
                career_detail: modalData.career_detail ? modalData.career_detail : null,
            })
            if(modalData.career_detail === null || modalData.career_detail === ''){
                setEditorState(EditorState.createEmpty())
            } else {
                const contentBlock = htmlToDraft(`${modalData.career_detail}`)
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                setEditorState(EditorState.createWithContent(contentState))
            }
        } else {
            setDetailData({
                id: null,
                career_title: null,
                career_description: null,
                career_detail: null,
            })
        }
    },[modalData])

    const handleEdit = (key,data) => {
        let tempData = {...detailData}
        tempData[`${key}`] = data
        setDetailData({...tempData})
    };

    const saveUpdatedData = async () => {
        try{
            let ModuleParams = {
                ...detailData,
                table:'career'
            }
            const params = new FormData()
            const keys = Object.keys(ModuleParams)
            keys.forEach((key,index) => {
                params.append(`${key}`, ModuleParams[key])
            });
            if(detailData?.id){
                await DynamicModuleManagementServices.updateFormModule(params,detailData?.id)
                modalRefresh()
            } else {
                await DynamicModuleManagementServices.createFormModule(params)
                modalRefresh()
            }
        } catch(error){
            modalRefresh()
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        }
    }

    return (
        <Modal
          open={true}
        >
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    height:'100vh',
                    width:'100vw'
                }}
            >
                <Container maxWidth="xl">
                    <Paper>
                        <Box p={2}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box>
                                    <Typography>{modalData ? `Edit ` : `Add `} Career</Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="close" onClick={modalHandle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                p={1}
                                sx={{
                                    maxHeight:'80vh',
                                    overflow:'auto'
                                }}
                            >
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Career Title"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={detailData?.career_title?detailData.career_title:''}
                                                    onChange={(value)=>handleEdit(`career_title`,value.target.value)}
                                                    placeholder="Career Title"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Career Description"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={detailData?.career_description?detailData.career_description:''}
                                                    onChange={(value)=>handleEdit(`career_description`,value.target.value)}
                                                    multiline
                                                    rows={4}
                                                    placeholder="Career Description"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                flexDirection={'column'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <Box>
                                                    <Typography>Career Detail</Typography>
                                                </Box>
                                                <Box sx={{
                                                    border:'1px solid #000',
                                                    width:'100%',
                                                    minHeight:'250px'
                                                }}>
                                                    <Editor 
                                                        editorState={editorState}
                                                        onEditorStateChange={(state)=> handleEditorState(state)}
                                                     />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={modalHandle}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={()=>saveUpdatedData()}
                                        color="success"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
}

export default MainPageModal;
