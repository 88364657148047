import axios from 'axios';

const API_KEY = `${process.env.REACT_APP_API_KEY}`;
const URL = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;

export const translateText = async (texts, targetLang = 'en') => {
  try {
    const response = await axios.post(URL, {
      q: texts,
      target: targetLang,
    });
    return response.data.data.translations.map((translation) => translation.translatedText);
  } catch (error) {
    console.error('Error translating text:', error);
    return texts;
  }
  // return texts.map((translation) => `--${translation}--`);
};
