import React, { useEffect, useState, useRef, Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service';
//css
import './ContactPage.css';
//UTILS//
import {encryptData,decryptData} from '../../../utils/GeneralHelper.js'
//UTILS//

const maxFileSize = 10000000;
const filetype = [".JPG",".jpg",".JPEG",".jpeg",".PNG",".png",".SVG",".svg"]

function MainPageModal({modalHandle,modalData,modalRefresh}) {
    const [mainId, setMainId] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState({
        id:null,
        contact_detail:null,
        contact_coordinate:null
    });
    const [tempDetailData, setTempDetailData] = useState({
        id:null,
        contact_item_title:null,
        contact_item_details:null,
        contact_item_type:'',
        contact_id:1,
    });
    const [detailPageData, setDetailPageData] = useState([]);
    
    //-----//
    const [editorState, setEditorState] = useState(()=>EditorState.createEmpty());
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const handleEditorState = (value) => {
        setEditorState(value)
        setPageData({...pageData,contact_detail:draftToHtml(rawContentState)})
    };
    //-----//

    useEffect(()=>{
        getModuleData()
    },[])

    const handleRefresh = async () => {
        getModuleData()
    }

    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
                id:encryptData(mainId),
                orderData:'id',
                orderDirection:'DESC',
                table:'contact'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                if(data.data[0].contact_detail === null || data.data[0].contact_detail === ''){
                    setEditorState(EditorState.createEmpty())
                } else {
                    const contentBlock = htmlToDraft(`${data.data[0].contact_detail}`)
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                    setEditorState(EditorState.createWithContent(contentState))
                }
                setPageData({...data.data[0]})
            }

            const detailData = await DynamicModuleManagementServices.getModule({
                contact_id:1,
                table:'contact_item'
            })
            if(detailData.data.length>0){
                setDetailPageData([...detailData.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }


    // useEffect(()=>{
    //     if(modalData?.id){
    //         setDetailData({
    //             id: modalData.id ? modalData.id : null,
    //             career_title: modalData.career_title ? modalData.career_title : null,
    //             career_description: modalData.career_description ? modalData.career_description : null,
    //             career_detail: modalData.career_detail ? modalData.career_detail : null,
    //         })
    //         if(modalData.career_detail === null || modalData.career_detail === ''){
    //             setEditorState(EditorState.createEmpty())
    //         } else {
    //             const contentBlock = htmlToDraft(`${modalData.career_detail}`)
    //             const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    //             setEditorState(EditorState.createWithContent(contentState))
    //         }
    //     } else {
    //         setDetailData({
    //             id: null,
    //             career_title: null,
    //             career_description: null,
    //             career_detail: null,
    //         })
    //     }
    // },[modalData])

    const handleEdit = (key,data) => {
        let tempData = {...pageData}
        tempData[`${key}`] = data
        setPageData({...tempData})
    };

    const handleTempEdit = (key,data) => {
        let tempData = {...tempDetailData}
        tempData[`${key}`] = data
        setTempDetailData({...tempData})
    };

    const handleContactItem = (key,data,idx) => {
        let tempData = [...detailPageData]
        tempData[idx][`${key}`] = data
        setDetailPageData([...tempData])
    };

    const addTempDetailData = () => {
        setDetailPageData([...detailPageData,tempDetailData])
        setTempDetailData({
            id:null,
            contact_item_title:null,
            contact_item_details:null,
            contact_item_type:'',
            contact_id:1,
        })
    };

    const saveUpdatedData = async () => {
        try{
            let ModuleParams = {
                ...pageData,
                table:'contact'
            }
            const params = new FormData()
            const keys = Object.keys(ModuleParams)
            keys.forEach((key,index) => {
                params.append(`${key}`, ModuleParams[key])
            });
            if(pageData?.id){
                await DynamicModuleManagementServices.updateFormModule(params,pageData?.id)
            } else {
                await DynamicModuleManagementServices.createFormModule(params)
            }
            if(detailPageData.length>0){
                for (const item of detailPageData) {
                    saveUpdatedDataContactItem(item)
                }
            }
        } catch(error){
            handleRefresh()
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            handleRefresh()
        }
    }

    const saveUpdatedDataContactItem = async (itemData) => {
        try{
            let ModuleParams = {
                ...itemData,
                table:'contact_item'
            }
            const params = new FormData()
            const keys = Object.keys(ModuleParams)
            keys.forEach((key,index) => {
                params.append(`${key}`, ModuleParams[key])
            });
            if(itemData?.id){
                await DynamicModuleManagementServices.updateFormModule(params,itemData?.id)
            } else {
                await DynamicModuleManagementServices.createFormModule(params)
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        }
    }

    return (
        <Container maxWidth="xl">
        {
            isLoading ?
            <CircularProgress />
            :
            <Paper>
                <Box p={2}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        p={1}
                    >
                        <Box>
                            <Typography>Contact</Typography>
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        p={1}
                        sx={{
                            maxHeight:'80vh',
                            overflow:'auto'
                        }}
                    >
                        <Box
                            py={1}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        flexDirection={'column'}
                                        sx={{
                                            height:'100%'
                                        }}
                                    >
                                        <Box>
                                            <Typography>Contact Detail</Typography>
                                        </Box>
                                        <Box sx={{
                                            border:'1px solid #000',
                                            width:'100%',
                                            minHeight:'250px'
                                        }}>
                                            <Editor 
                                                editorState={editorState}
                                                onEditorStateChange={(state)=> handleEditorState(state)}
                                             />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            py={1}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        sx={{
                                            height:'100%'
                                        }}
                                    >
                                        <TextField
                                            label="Contact Coordinate"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width:'100%'
                                            }}
                                            value={pageData?.contact_coordinate?pageData.contact_coordinate:''}
                                            onChange={(value)=>handleEdit(`contact_coordinate`,value.target.value)}
                                            placeholder="Contact Coordinate"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                        >
                            <Box
                                display={'flex'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                flexDirection={'column'}
                                sx={{
                                    width:'30%'
                                }}
                            >
                                <Box>
                                    <Typography>Contact Item</Typography>
                                </Box>
                                <Box
                                    py={1}
                                    pr={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <select
                                                    style={{
                                                        height:'40px',
                                                        width:'100%',
                                                        border:'1px solid #dbdbdb',
                                                        borderRadius:'3px'
                                                    }}
                                                    onChange={(value)=>{handleTempEdit(`contact_item_type`,value.target.value)}}
                                                    value={tempDetailData?.contact_item_type}
                                                >
                                                    <option value={''}>Please choose a contact type</option>
                                                    <option value={'phone'}>Phone</option>
                                                    <option value={'email'}>Email</option>
                                                    <option value={'address'}>Address</option>
                                                </select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Contact Item Title"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={tempDetailData?.contact_item_title?tempDetailData.contact_item_title:''}
                                                    onChange={(value)=>handleTempEdit(`contact_item_title`,value.target.value)}
                                                    placeholder="Contact Item Title"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Contact Item Details"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={tempDetailData?.contact_item_details?tempDetailData.contact_item_details:''}
                                                    onChange={(value)=>handleTempEdit(`contact_item_details`,value.target.value)}
                                                    multiline
                                                    rows={4}
                                                    placeholder="Contact Item Details"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <Button 
                                                    variant="contained"
                                                    onClick={()=>addTempDetailData()}
                                                    color="success"
                                                >
                                                    Add To Contact Item List
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                flexDirection={'column'}
                                sx={{
                                    width:'70%',
                                }}
                            >
                                <Box>
                                    <Typography>Contact Item List</Typography>
                                </Box>
                                <Box
                                    py={1}
                                    pl={1}
                                    sx={{
                                        width:'100%',
                                        maxHeight:'300px',
                                        overflow:'auto'
                                    }}
                                >
                                    {
                                        detailPageData.length===0?
                                        <Box
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            sx={{
                                                width:'100%',
                                                height:'300px'
                                            }}
                                        >
                                            <Typography variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                                No Product Category data available yet . . .
                                            </Typography>
                                        </Box>
                                        :
                                        detailPageData.map((data,index) => 
                                            <Box key={index} py={1}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={2}>
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={'flex-start'}
                                                            alignItems={'flex-start'}
                                                            sx={{
                                                                height:'100%'
                                                            }}
                                                        >
                                                            <select
                                                                style={{
                                                                    height:'40px',
                                                                    width:'100%',
                                                                    border:'1px solid #dbdbdb',
                                                                    borderRadius:'3px'
                                                                }}
                                                                onChange={(value)=>{handleContactItem(`contact_item_type`,value.target.value,index)}}
                                                                value={data?.contact_item_type}
                                                            >
                                                                <option value={''}>Please choose a contact type</option>
                                                                <option value={'phone'}>Phone</option>
                                                                <option value={'email'}>Email</option>
                                                                <option value={'address'}>Address</option>
                                                            </select>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={'flex-start'}
                                                            alignItems={'flex-start'}
                                                            sx={{
                                                                height:'100%'
                                                            }}
                                                        >
                                                            <TextField
                                                                label="Contact Item Title"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    width:'100%'
                                                                }}
                                                                value={data?.contact_item_title?data.contact_item_title:''}
                                                                onChange={(value)=>handleContactItem(`contact_item_title`,value.target.value,index)}
                                                                placeholder="Contact Item Title"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={'flex-start'}
                                                            alignItems={'flex-start'}
                                                            sx={{
                                                                height:'100%'
                                                            }}
                                                        >
                                                            <TextField
                                                                label="Contact Item Details"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    width:'100%'
                                                                }}
                                                                value={data?.contact_item_details?data.contact_item_details:''}
                                                                onChange={(value)=>handleContactItem(`contact_item_details`,value.target.value,index)}
                                                                multiline
                                                                rows={4}
                                                                placeholder="Contact Item Details"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box
                            py={1}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        sx={{
                                            height:'100%'
                                        }}
                                    >
                                        <TextField
                                            label="Career Description"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width:'100%'
                                            }}
                                            value={detailData?.career_description?detailData.career_description:''}
                                            onChange={(value)=>handleEdit(`career_description`,value.target.value)}
                                            multiline
                                            rows={4}
                                            placeholder="Career Description"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box> */}
                    </Box>
                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        p={1}
                    >
                        <Box px={2}>
                            <Button 
                                variant="contained"
                                onClick={()=>saveUpdatedData()}
                                color="success"
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        }
        </Container>
    );
}

export default MainPageModal;
