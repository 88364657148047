//main//
import React, { useEffect, useState } from 'react';
import moment from 'moment';
//material//
import { 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    CircularProgress,
    Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service'
//component//
import SocialMediaPageModal from './SocialMediaPageModal';

const StyledPageination = styled(Pagination)(({theme}) => ({
  '&& .Mui-selected' : {
    backgroundColor: '#e5e5e5',
    color:'#000000'
  },
}))

function MainPageData() {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [pageData, setPageData] = useState([]);
    const [masterCategoryData, setMasterCategoryData] = useState([]);

    const [orderBy, setOrderBy] = useState('id');
    const [orderDirection, setOrderDirection] = useState('asc');
    const [search, setSearch] = useState(null);
  
    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(1);
    };
    
    const dataShowInfo = () => {
      let firstData = 0
      let endData = 0
      for (let i = 0; i < pageData.length; i++) {
        if(i==0){
          firstData = i + ((page-1) * rowsPerPage) + 1
        }
        if(i==pageData.length-1){
          endData = i + ((page-1) * rowsPerPage) + 1
        }
      }
      let result = `${firstData}-${endData}`
      return result
    }

    const modalHandleOpen = () => {
        setModalOpen(!modalOpen)
    };

    const modalHandleClose = () => {
        setModalData(null)
        setModalOpen(!modalOpen)
    };

    const handleEdit = (row) => {
        setModalData({...row})
        setModalOpen(!modalOpen)
    };

    const handleRefresh = async () => {
        modalHandleClose()
        getModuleData()
    }

    useEffect(()=>{
        getModuleData()
    },[page,rowsPerPage])

    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
                page:page,
                perPage:rowsPerPage,
                orderData:'id',
                orderDirection:'ASC',
                table:'social_media'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
                const total = Math.ceil(data.pagination['total'] / parseFloat(rowsPerPage));
                await setTotalPage(total);
                setTotalData(data.pagination['total'])
            }

            const masterCategory = await DynamicModuleManagementServices.getModule({
                table:'bisnis'
            })
            if(masterCategory.data.length>0){
                for (const data of masterCategory.data) {
                    data.value = data.id
                    data.label=`${data.nama_bisnis	}`
                }
                setMasterCategoryData([...masterCategory.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }

    const getBisnisName = (data) => {
        let bisnisData = masterCategoryData.filter((x) => x.id == data.bisnis_id)
        if(bisnisData.length>0){
            return bisnisData[0].label
        } else {
            if(data.bisnis_id == 0){
                return `Prosperity Group`
            } else {
                return ``
            }
        }
    }
    
    return (
        <>
            <Box>
                <Paper
                elevation={3}
                sx={{
                    height:'100%',
                    padding:2,
                    paddingBottom:2,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    flexDirection:'column'
                }}
                >
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        sx={{width:'100%'}}
                        py={2}
                    >
                        <Box>
                            <Typography>Social Media</Typography>
                        </Box>
                        <Box>
                            <Button 
                                variant="contained"
                                startIcon={
                                    <AddBoxIcon />
                                }
                                onClick={()=>modalHandleOpen()}
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>
                    {
                        isLoading ?
                        <CircularProgress />
                        :
                        <>
                            <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
                                <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{padding:2}}>
                                                Social Media
                                            </TableCell>
                                            <TableCell sx={{padding:2}}>
                                                Social Media Link
                                            </TableCell>
                                            <TableCell sx={{padding:2}}>
                                                Brand
                                            </TableCell>
                                            <TableCell align="right" sx={{padding:2}}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {pageData.length>0&&pageData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ 
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <TableCell component="th" scope="row" sx={{padding:2}}>
                                                {row.social_media_title}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{padding:2}}>
                                                {row.social_media_link}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{padding:2}}>
                                                {getBisnisName(row)}
                                            </TableCell>
                                            <TableCell align="right" sx={{padding:2}}>
                                                <IconButton 
                                                    aria-label="edit" 
                                                    onClick={()=>handleEdit(row)}
                                                    disabled={row.module_type == 'Master'?true:false}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display="flex" justifyContent="space-between" sx={{pt:3,width:'100%'}}>
                                <Box display="flex" flexDirection="row">
                                    <Typography>{`Showing ${dataShowInfo()} of ${totalData} items`}</Typography> 
                                    <Typography sx={{pl:2,pr:2}}>|</Typography> 
                                    <Typography>Show</Typography>
                                    <Box sx={{pl:2,pr:2}}>
                                    <select 
                                        value={rowsPerPage} 
                                        style={{borderRadius:5}}
                                        onChange={(selected) => handleChangeRowsPerPage(selected)}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                    </Box>
                                    <Typography>Data Entries</Typography>
                                </Box>
                                <Box>
                                    <StyledPageination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={(event,page) => handleChangePage(event,page)} />
                                </Box>
                            </Box>
                        </>
                    }
                </Paper>
            </Box>
            {
                modalOpen &&
                <SocialMediaPageModal 
                    modalHandle={modalHandleClose}
                    modalData={modalData}
                    modalRefresh={handleRefresh}
                    masterCategoryData={masterCategoryData}
                />
            }
        </>
    );
}

export default MainPageData;
