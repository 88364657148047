import axios from "axios";
import api from "../utils/api";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class ModuleManagement {
    getOTP = async (params) => {
        params = {
        ...params
        }
        return await api.postAxios(
        "/prosperity-auth/otp-login",
        params,
        { cancelToken: source.token }
        );
    };

    verifyOTP = async (params) => {
        params = {
        ...params
        }
        return await api.postAxios(
        "/prosperity-auth/otp-verify",
        params,
        { cancelToken: source.token }
        );
    };
}

export default new ModuleManagement();
