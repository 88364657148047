import React, { useEffect, useState, useRef, Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service';
//css
import './CompanyPage.css';
//UTILS//
import {encryptData,decryptData} from '../../../utils/GeneralHelper.js'
//UTILS//

const maxFileSize = 10000000;
const filetype = [".JPG",".jpg",".JPEG",".jpeg",".PNG",".png",".SVG",".svg"]

function MainPageModal({modalHandle,modalData,modalRefresh}) {
    const [mainId, setMainId] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState({
        id:null,
        company_history:null,
        company_address	:null
    });
    
    //-----//
    const [editorState, setEditorState] = useState(()=>EditorState.createEmpty());
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const handleEditorState = (value) => {
        setEditorState(value)
        setPageData({...pageData,company_history:draftToHtml(rawContentState)})
    };
    //-----//

    useEffect(()=>{
        getModuleData()
    },[])

    const handleRefresh = async () => {
        getModuleData()
    }

    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
                id:encryptData(mainId),
                orderData:'id',
                orderDirection:'DESC',
                table:'company'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                if(data.data[0].company_history === null || data.data[0].company_history === ''){
                    setEditorState(EditorState.createEmpty())
                } else {
                    const contentBlock = htmlToDraft(`${data.data[0].company_history}`)
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                    setEditorState(EditorState.createWithContent(contentState))
                }
                setPageData({...data.data[0]})
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }

    const handleEdit = (key,data) => {
        let tempData = {...pageData}
        tempData[`${key}`] = data
        setPageData({...tempData})
    };

    const saveUpdatedData = async () => {
        try{
            let ModuleParams = {
                ...pageData,
                table:'company'
            }
            const params = new FormData()
            const keys = Object.keys(ModuleParams)
            keys.forEach((key,index) => {
                params.append(`${key}`, ModuleParams[key])
            });
            if(pageData?.id){
                await DynamicModuleManagementServices.updateFormModule(params,pageData?.id)
            } else {
                await DynamicModuleManagementServices.createFormModule(params)
            }
        } catch(error){
            handleRefresh()
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            handleRefresh()
        }
    }

    return (
        <Container maxWidth="xl">
        {
            isLoading ?
            <CircularProgress />
            :
            <Paper>
                <Box p={2}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        p={1}
                    >
                        <Box>
                            <Typography>Company</Typography>
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        p={1}
                        sx={{
                            maxHeight:'80vh',
                            overflow:'auto'
                        }}
                    >
                        <Box
                            py={1}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        flexDirection={'column'}
                                        sx={{
                                            height:'100%'
                                        }}
                                    >
                                        <Box>
                                            <Typography>Company History</Typography>
                                        </Box>
                                        <Box sx={{
                                            border:'1px solid #000',
                                            width:'100%',
                                            minHeight:'250px'
                                        }}>
                                            <Editor 
                                                editorState={editorState}
                                                onEditorStateChange={(state)=> handleEditorState(state)}
                                             />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            py={1}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                        sx={{
                                            height:'100%'
                                        }}
                                    >
                                        <TextField
                                            label="Company Address"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width:'100%'
                                            }}
                                            value={pageData?.company_address?pageData.company_address:''}
                                            onChange={(value)=>handleEdit(`company_address`,value.target.value)}
                                            multiline
                                            rows={4}
                                            placeholder="Company Address"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        flexDirection={'row'}
                        p={1}
                    >
                        <Box px={2}>
                            <Button 
                                variant="contained"
                                onClick={()=>saveUpdatedData()}
                                color="success"
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        }
        </Container>
    );
}

export default MainPageModal;
