import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";
//services//
import DynamicModuleManagementServices from '../../../services/DynamicProsperityModuleManagement.service';

const maxFileSize = 10000000;
const filetype = [".JPG",".jpg",".JPEG",".jpeg",".PNG",".png",".SVG",".svg"]

function MainPageModal({modalHandle,modalData,modalRefresh,masterCategoryData}) {
    const toaster = useToaster();
    const [detailData, setDetailData] = useState(null);
  
    const [tempImage1, setTempImage1] = useState([]);
    const [imageInfo1, setImageInfo1] = useState([]);
    const [tempImage2, setTempImage2] = useState([]);
    const [imageInfo2, setImageInfo2] = useState([]);
    //-------//
    const onFileCheck1 = (data) => {
        if(data.length > 1){
            alert("You can only upload 1 file at a time", 5000);
        }else{
            if(data.length) {
                let valid = false;
                let filename = data[0].blobFile.name;
                filetype.forEach((type, index) =>{
                    console.log(type)
                    if(filename.toLowerCase().includes(type)){
                        valid = true
                    }
                })
                if(valid){
                    console.log(data[0]);
                    if(data[0].blobFile.size > maxFileSize){
                        alert("File Exceeding Max Limit of 10mb", 5000);
                    }else{
                        setTempImage1(data)
                        const objectUrl = URL.createObjectURL(data[0].blobFile)
                        setImageInfo1([{
                            url:objectUrl,
                            name:data[0].name
                        }])
                    }
                }else{
                    alert("Please upload a correct file extension!", 5000);
                }
            }
        }
    }
    const clearIconData1 = () => {
        setTempImage1([])
        setImageInfo1([])
        URL.revokeObjectURL(imageInfo1)
    }
  
    const onFileCheck2 = (data) => {
        if(data.length > 1){
            alert("You can only upload 1 file at a time", 5000);
        }else{
            if(data.length) {
                let valid = false;
                let filename = data[0].blobFile.name;
                filetype.forEach((type, index) =>{
                    console.log(type)
                    if(filename.toLowerCase().includes(type)){
                        valid = true
                    }
                })
                if(valid){
                    console.log(data[0]);
                    if(data[0].blobFile.size > maxFileSize){
                        alert("File Exceeding Max Limit of 10mb", 5000);
                    }else{
                        setTempImage2(data)
                        const objectUrl = URL.createObjectURL(data[0].blobFile)
                        setImageInfo2([{
                            url:objectUrl,
                            name:data[0].name
                        }])
                    }
                }else{
                    alert("Please upload a correct file extension!", 5000);
                }
            }
        }
    }
    const clearIconData2 = () => {
        setTempImage2([])
        setImageInfo2([])
        URL.revokeObjectURL(imageInfo2)
    }
    //-----//

    useEffect(()=>{
        if(modalData?.id){
            setDetailData({
                id: modalData.id ? modalData.id : null,
                nama_bisnis: modalData.nama_bisnis ? modalData.nama_bisnis : null,
                detail_bisnis: modalData.detail_bisnis ? modalData.detail_bisnis : null,
                logo_bisnis: modalData.logo_bisnis ? modalData.logo_bisnis : null,
                bisnis_category_id: modalData.bisnis_category_id ? modalData.bisnis_category_id : null,
                header_image: modalData.header_image ? modalData.header_image : null
            })
            if(modalData.logo_bisnis){
                setImageInfo1([{
                  url:`${process.env.REACT_APP_API_URL}/prosperity-file/${modalData.logo_bisnis}`,
                  name:modalData.logo_bisnis
                }])
            }
            if(modalData.header_image){
                setImageInfo2([{
                  url:`${process.env.REACT_APP_API_URL}/prosperity-file/${modalData.header_image}`,
                  name:modalData.header_image
                }])
            }
        } else {
            setDetailData({
                id: null,
                nama_bisnis: null,
                detail_bisnis: null,
                logo_bisnis: null,
                bisnis_category_id: null,
                header_image: null
            })
        }
    },[modalData])

    const handleEdit = (key,data) => {
        let tempData = {...detailData}
        tempData[`${key}`] = data
        setDetailData({...tempData})
    };

    const saveUpdatedData = async () => {
        try{
            let ModuleParams = {
                ...detailData,
                table:'bisnis'
            }
            const params = new FormData()
            const keys = Object.keys(ModuleParams)
            keys.forEach((key,index) => {
                params.append(`${key}`, ModuleParams[key])
            });
            let imageList = []
            if(tempImage1.length > 0){
              const blobFile1 = new File([tempImage1[0].blobFile], tempImage1[0].name, tempImage1[0].type)
              params.append("files", blobFile1)
              imageList.push({
                itemName:"logo_bisnis"
              })
            }
            if(tempImage2.length > 0){
              const blobFile2 = new File([tempImage2[0].blobFile], tempImage2[0].name, tempImage2[0].type,)
              params.append("files", blobFile2)
              imageList.push({
                itemName:"header_image"
              })
            }
            if(imageList.length>0){
                params.append("image_list", JSON.stringify(imageList))
            }
            if(detailData?.id){
                await DynamicModuleManagementServices.updateFormModule(params,detailData?.id)
                modalRefresh()
            } else {
                await DynamicModuleManagementServices.createFormModule(params)
                modalRefresh()
            }
        } catch(error){
            modalRefresh()
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        }
    }

    return (
        <Modal
          open={true}
        >
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    height:'100vh',
                    width:'100vw'
                }}
            >
                <Container maxWidth="sm">
                    <Paper>
                        <Box p={2}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box>
                                    <Typography>{modalData ? `Edit ` : `Add `} Business</Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="close" onClick={modalHandle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                p={1}
                            >
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <select
                                                    style={{
                                                        height:'40px',
                                                        width:'100%',
                                                        border:'1px solid #dbdbdb',
                                                        borderRadius:'3px'
                                                    }}
                                                    onChange={(value)=>{handleEdit(`bisnis_category_id`,value.target.value)}}
                                                    value={detailData?.bisnis_category_id}
                                                >
                                                    <option value={''}>Please choose a business category</option>
                                                    {
                                                        masterCategoryData&&masterCategoryData.length>0&&masterCategoryData.map((data)=>
                                                            <option value={data.value}>{data.label}</option>
                                                        )
                                                    }
                                                </select>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Business Name"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={detailData?.nama_bisnis?detailData.nama_bisnis:''}
                                                    onChange={(value)=>handleEdit(`nama_bisnis`,value.target.value)}
                                                    placeholder="Business Name"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                <TextField
                                                    label="Business Details"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                    value={detailData?.detail_bisnis?detailData.detail_bisnis:''}
                                                    onChange={(value)=>handleEdit(`detail_bisnis`,value.target.value)}
                                                    multiline
                                                    rows={4}
                                                    placeholder="Business Details"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography>Business Logo</Typography>
                                            </Box>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                                {
                                                    imageInfo1.length>0?
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'column'}
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                        sx={{
                                                            width:'100%'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width:'50%'
                                                            }}
                                                        >
                                                            <img src={imageInfo1[0].url} style={{width:'100%'}} />
                                                        </Box>
                                                        <Box
                                                            display={'flex'}
                                                            flexDirection={'row'}
                                                        >
                                                            <Box>
                                                                <Typography style={{color:'#ff0000'}}>{imageInfo1[0].name}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography pl={2} style={{cursor:'pointer'}} onClick={() => clearIconData1()}>clear</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Uploader 
                                                        fileListVisible={false}
                                                        value={tempImage1}
                                                        autoUpload={false}
                                                        onChange={onFileCheck1}
                                                        accept=".JPG,.jpg,.JPEG,.jpeg,PNG,.png,.SVG,.svg"
                                                        multiple={false}
                                                        disabled={imageInfo1.length>0 ? true : false}
                                                        fileList={tempImage1}
                                                        draggable
                                                        style={{width:'100%'}}
                                                    >
                                                        <div style={{ height: 100, width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <span>Click or Drag files to this area to upload Business Logo</span>
                                                        </div>
                                                    </Uploader>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    py={1}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography>Business Details Header</Typography>
                                            </Box>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'flex-start'}
                                                alignItems={'flex-start'}
                                                sx={{
                                                    height:'100%'
                                                }}
                                            >
                                            {
                                                imageInfo2.length>0?
                                                <Box
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    sx={{
                                                        width:'100%'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width:'50%'
                                                        }}
                                                    >
                                                        <img src={imageInfo2[0].url} style={{width:'100%'}} />
                                                    </Box>
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                    >
                                                        <Box>
                                                            <Typography style={{color:'#ff0000'}}>{imageInfo2[0].name}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography pl={2} style={{cursor:'pointer'}} onClick={() => clearIconData2()}>clear</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                :
                                                <Uploader 
                                                    fileListVisible={false}
                                                    value={tempImage2}
                                                    autoUpload={false}
                                                    onChange={onFileCheck2}
                                                    accept=".JPG,.jpg,.JPEG,.jpeg,PNG,.png,.SVG,.svg"
                                                    multiple={false}
                                                    disabled={imageInfo2.length>0 ? true : false}
                                                    fileList={tempImage2}
                                                    draggable
                                                    style={{width:'100%'}}
                                                >
                                                    <div style={{ height: 100, width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span>Click or Drag files to this area to upload Business Details Header</span>
                                                    </div>
                                                </Uploader>
                                            }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={modalHandle}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box px={2}>
                                    <Button 
                                        variant="contained"
                                        onClick={()=>saveUpdatedData()}
                                        color="success"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
}

export default MainPageModal;
