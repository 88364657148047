import React, {useEffect,useState} from 'react'
import {
    Container,
    Box,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useTranslation } from '../../contex/TranslationContext';
//media
import instagram_1 from '../../assets/images/icon/instagram.png';
import tiktok_1 from '../../assets/images/icon/tiktok.png';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

const NavHeaderComponent = () => {
    const { language, translateAllText, handleTranslate, resetTranslate } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [dreawerIsOpen,setDrawerIsOpen] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const Navigate = useNavigate()
    const [nav,setNav] = useState(null)
    const [open1,setOpen1] = useState(false)
    const [open2,setOpen2] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        getModuleData()
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [language]); // Empty dependency array ensures the effect runs only once

    const openLinkInNewTab = (url) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              orderData:'id',
              orderDirection:'ASC',
              bisnis_id:0,
              table:'social_media'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            finishFetch()
        }
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-nav-header]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-nav-header]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-nav-header]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };
    
    return( 
        <Box display={"flex"} sx={{backgroundColor:scrollPosition > window.innerHeight ?'rgba(0, 0, 0, 1)':window.location.pathname!="/"?'rgba(0, 0, 0, 1)':'rgba(0, 0, 0, 0.3)'}}>
            <Container maxWidth="xl">
                <Box display={"flex"} sx={{height:'5vh'}}>
                    <Box 
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            width:'100%',
                            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                        }}
                    >
                        <Box 
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={'start'}
                            alignItems={"center"}
                        >
                            <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);Navigate('/');}}>
                                <Typography variant="h6" sx={{color:'#ffffff'}}>
                                    PROSPERITY
                                </Typography>
                            </Box>
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="About Us"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("About Us");Navigate('/reload');Navigate('/about-us');}}
                                    onMouseOver={()=>{setOpen1(false);setOpen2(false);}}
                                >
                                    <Typography data-translate-nav-header>
                                        Perusahaan
                                    </Typography>
                                </Box>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Global Promo And News"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("Global Promo And News");Navigate('/reload');Navigate('/global-promo-and-news');}}
                                    onMouseOver={()=>{setOpen1(false);setOpen2(false);}}
                                >
                                    <Typography data-translate-nav-header>
                                        Berita Dan Promo
                                    </Typography>
                                </Box>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Contact Us"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("Contact Us");Navigate('/contact-us');}}
                                    onMouseOver={()=>{setOpen1(false);setOpen2(false);}}
                                >
                                    <Typography data-translate-nav-header>
                                        Kontak
                                    </Typography>
                                </Box>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Careers"?'#00b7ff':'#ffffff'}}
                                    onClick={()=>{setNav("Careers");Navigate('/career-list');}}
                                    onMouseOver={()=>{setOpen1(false);setOpen2(false);}}
                                >
                                    <Typography data-translate-nav-header>
                                        Karir
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box 
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={'start'}
                            alignItems={"center"}
                        >
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:'#000000',
                                        color:language=='en'?'#00b7ff':'#ffffff',
                                        width:'auto',
                                        borderRadius:5,
                                        cursor:'pointer',
                                    }}
                                    px={2}
                                    mx={1}
                                    onClick={()=> handleTranslate()}
                                >
                                    <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>ENG</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        backgroundColor:'#000000',
                                        color:language=='id'?'#00b7ff':'#ffffff',
                                        width:'auto',
                                        borderRadius:5,
                                        cursor:'pointer',
                                    }}
                                    px={2}
                                    mx={1}
                                    onClick={()=> resetTranslate()}
                                >
                                    <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>IND</Typography>
                                </Box>
                                {
                                    pageData.length>0&&pageData.map((data,index) => 
                                        <Box 
                                            display={"flex"}
                                            flexDirection={"row"}
                                            alignItems={'center'}
                                            py={1}
                                            mx={1}
                                            sx={{
                                                cursor:'pointer'
                                            }}
                                            onClick={()=> openLinkInNewTab(`${data.social_media_link}`)}
                                            key={index}
                                        >
                                            <img src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.social_media_image}`} width={30} height={30} />
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box 
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            width:'100%',
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }
                        }}
                    >
                        <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);Navigate('/');}}>
                            <Typography variant="h6" sx={{color:'#ffffff'}}>
                                PROSPERITY
                            </Typography>
                        </Box>
                        <Box 
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={'flex-end'}
                            alignItems={"center"}
                            sx={{
                                width:'100%',
                            }}
                        >
                            <Box p={1} sx={{cursor:'pointer'}} onClick={()=>{setDrawerIsOpen(!dreawerIsOpen)}}>
                                <MenuIcon sx={{ color:'#fff', fontSize: 40 }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Drawer open={dreawerIsOpen} onClose={() => setDrawerIsOpen(!dreawerIsOpen)}>
                <Box sx={{ width: 250, height:'100%', backgroundColor:'#4f4f4f' }} role="presentation" onClick={() => setDrawerIsOpen(!dreawerIsOpen)}>
                    <List>
                        <ListItem disablePadding>
                            <Typography variant="h6" sx={{color:'#ffffff'}}>
                                PROSPERITY
                            </Typography>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem disablePadding onClick={()=>{setNav("About Us");Navigate('/about-us');}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="About Us"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography data-translate-nav-header>
                                    Perusahaan
                                </Typography>
                            </Box>
                        </ListItem>
                        <ListItem disablePadding onClick={()=>{setNav("Global Promo And News");Navigate('/global-promo-and-news');}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="Global Promo And News"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography data-translate-nav-header>
                                    Promo Dan Berita
                                </Typography>
                            </Box>
                        </ListItem>
                        <ListItem disablePadding onClick={()=>{setNav("Contact Us");Navigate('/contact-us');}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="Contact Us"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography data-translate-nav-header>
                                    Kontak
                                </Typography>
                            </Box>
                        </ListItem>
                        <ListItem disablePadding onClick={()=>{setNav("Careers");Navigate('/career-list');}}>
                            <Box 
                                p={1} 
                                sx={{cursor:'pointer',color:nav=="Careers"?'#00b7ff':'#ffffff'}}
                            >
                                <Typography>
                                    Careers
                                </Typography>
                            </Box>
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem disablePadding>
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:'#000000',
                                        color:language=='en'?'#00b7ff':'#ffffff',
                                        width:'auto',
                                        borderRadius:5,
                                        cursor:'pointer',
                                    }}
                                    px={2}
                                    mx={1}
                                    onClick={()=> handleTranslate()}
                                >
                                    <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>ENG</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        backgroundColor:'#000000',
                                        color:language=='id'?'#00b7ff':'#ffffff',
                                        width:'auto',
                                        borderRadius:5,
                                        cursor:'pointer',
                                    }}
                                    px={2}
                                    mx={1}
                                    onClick={()=> resetTranslate()}
                                >
                                    <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>IND</Typography>
                                </Box>
                            </Box>
                        </ListItem>
                        <ListItem disablePadding>
                            {
                                pageData.length>0&&pageData.map((data,index) => 
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                        mx={1}
                                        sx={{
                                            cursor:'pointer'
                                        }}
                                        onClick={()=> openLinkInNewTab(`${data.social_media_link}`)}
                                        key={index}
                                    >
                                        <img src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.social_media_image}`} width={30} height={30} />
                                    </Box>
                                )
                            }
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    )
}

export default NavHeaderComponent