import React, { useEffect , useState, useRef } from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    CircularProgress,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
//services//
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//

function SliderExperimental({data}) {
  const { language, translateAllText } = useTranslation();
  const [originalText, setOriginalText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pageData, setPageData] = useState([]);

  const Navigate = useNavigate()
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  let sliderRefSmall = useRef(null);
  const nextSmall = () => {
    sliderRefSmall.slickNext();
  };
  const previousSmall = () => {
    sliderRefSmall.slickPrev();
  };
  const [activeSlide, setActiveSlide] = useState(0);
  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: false,
    swipe: false,  
  });
  const [settingsSmall, setSettingsSmall] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: false,
    swipe: false,  
  });
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'none'}}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'none' }}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    if(data.id){
      getModuleData(data?.id)
    }
  }, [data,language]);

  const getModuleData = async (id) => {
    setIsLoading(true)
    try{
        let params = {
          bisnis_category_id:id,
          orderData:'id',
          orderDirection:'ASC',
          table:'bisnis'
        }
        const data = await DynamicModuleManagementServices.getModule(params)
        if(data.data.length>0){
            setPageData([...data.data])
        }
    } catch(error){
        alert(`There is and error`)
        console.warn(`There is and error`, error);
    } finally{
      finishFetch()
    }
  }

  const finishFetch = async () => {
      if(originalText.length==0){
          const elements = document.querySelectorAll('[data-translate-slider-business-category]');
          const texts = Array.from(elements).map((el) => el.innerText);
          setOriginalText([...texts])
      }
      if(language=='en'){
        translateProcess()
      } 
      else {
          if(originalText.length>0){
              const elements = document.querySelectorAll('[data-translate-slider-business-category]');
              elements.forEach((el, idx) => {
                el.innerText = originalText[idx];
              });
          }
      }
      setIsLoading(false)
  };

  const translateProcess = async () => {
    const elements = document.querySelectorAll('[data-translate-slider-business-category]');
    const texts = Array.from(elements).map((el) => el.innerText);
    const newTranslations = await translateAllText(texts);
    elements.forEach((el, idx) => {
      el.innerText = newTranslations[texts[idx]];
    });
  };

  return (
    <Box sx={{backgroundColor:'#ffffff'}} py={5}>
      <Box sx={{backgroundColor:'#f5f5f5'}}>
        <Box py={5} sx={{position:'relative',display:isLoading?'block':'none'}}>
            <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                <CircularProgress />
            </Container>
        </Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              position:'relative',
              display:isLoading?'none':'block'
            }}
            py={5}
          >
            <Box
              sx={{
                display:'flex',
                alignItems:'start',
                justifyContent:'center',
                position:'absolute',
                top:0,
                height:'100%',
                width:'100%',
              }}
            >
              <Box
                sx={{
                  position:'absolute',
                  top:-10,
                  backgroundColor:'#000000',
                  color:'#ffffff',
                  width:'auto',
                  borderRadius:5
                }}
                px={7}
              >
                <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>{data?.nama_bisnis_category}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
              {
                  pageData.length===0?
                  <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                          width:'100%',
                          height:'200px'
                      }}
                  >
                      <Typography data-translate-slider-business-category variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                          Tidak ada data brand untuk saat ini . . .
                      </Typography>
                  </Box>
                  :
                  <>
                  <Slider
                    ref={slider => {
                      sliderRef = slider;
                    }}
                    {...settings}
                  >
                    {
                      pageData.map((item,key) =>
                        <Box
                          sx={{
                            width:'100%',
                            height:'300px',
                            cursor:'pointer'
                          }}
                          onClick={()=>{Navigate(`/details/${encryptData(item.id)}`)}} 
                          key={key}
                        >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                                height:'100%',
                              }}
                            >
                              <img 
                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${item.logo_bisnis}`}
                                style={{
                                  width:'100%',
                                }} 
                              />
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                              }}
                            >
                              <Typography pt={1} gutterBottom variant="h5" component="div" textAlign={"center"} style={{fontWeight:'bold'}}>
                                {item.nama_bisnis}
                              </Typography>
                            </Box>
                        </Box>
                      )
                    }
                  </Slider>
                  {
                    activeSlide !== 0 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        left:{md: 0, lg: 0, xl: -75},
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={previous}
                    >
                      <NavigateBeforeIcon sx={{ fontSize: {md: 50, lg: 50, xl: 150}  }} />
                    </Box>
                  }
                  {
                    pageData.length > settings.slidesToShow && activeSlide !== pageData.length - 5 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        right:{md: 0, lg: 0, xl: -75},
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={next}
                    >
                      <NavigateNextIcon sx={{ fontSize: {md: 50, lg: 50, xl: 150} }} />
                    </Box>
                  }
                  </>
              }
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
              {
                  pageData.length===0?
                  <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                          width:'100%',
                          height:'200px'
                      }}
                  >
                      <Typography data-translate-slider-business-category variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                          Tidak ada data brand untuk saat ini . . .
                      </Typography>
                  </Box>
                  :
                  <>
                  <Slider
                    ref={slider => {
                      sliderRefSmall = slider;
                    }}
                    {...settingsSmall}
                  >
                    {
                      pageData.map((item,key) =>
                        <Box
                          sx={{
                            width:'100%',
                            height:'300px',
                            cursor:'pointer'
                          }}
                          onClick={()=>{Navigate(`/details/${encryptData(item.id)}`)}} 
                          key={key}
                        >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                                height:'100%',
                              }}
                            >
                              <img 
                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${item.logo_bisnis}`}
                                style={{
                                  width:'100%',
                                }} 
                              />
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                              }}
                            >
                              <Typography pt={1} gutterBottom variant="h5" component="div" textAlign={"center"} style={{fontWeight:'bold'}}>
                               {item.nama_bisnis}
                              </Typography>
                            </Box>
                        </Box>
                      )
                    }
                  </Slider>
                  {
                    activeSlide !== 0 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        left:-0,
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={previousSmall}
                    >
                      <NavigateBeforeIcon sx={{ fontSize: 50 }} />
                    </Box>
                  }
                  {
                    pageData.length > settingsSmall.slidesToShow && activeSlide !== 1 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        right:-0,
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={nextSmall}
                    >
                      <NavigateNextIcon sx={{ fontSize: 50 }} />
                    </Box>
                  }
                  </>
              }
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default SliderExperimental;
