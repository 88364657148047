import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import rootReducer from './reducers/index'

const loggerMiddleware = (store) => (next) => (action) => {
    console.group(action.type)
    console.info("dispatching", action)
    let result = next(action)
    console.log("next state", store.getState());
    console.groupEnd()
    return result
}

const round = (number) => Math.round(number * 100)/100

const monitorReducerEnhancer = (createStore) => (
    reducer,
    initialState,
    enhancer
) => {
    const monitoredReducer = (state, action) => {
        const start = performance.now()
        const newState = reducer(state, action)
        const end = performance.now()
        const diff = round(end - start)
        console.log("reducer process time :", diff);
        return newState
    }
    return createStore(monitoredReducer, initialState, enhancer)
}

const persistedReducer = persistReducer({
    key: 'root',
    storage,
    blacklist: ['notifReducer']
},rootReducer)

function configureAppStore() {
    return configureStore({
        reducer: persistedReducer,
    })
}

export default configureAppStore()



