import React, { useEffect, useState } from 'react';
import { 
    Box,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
//redux//
import { useDispatch } from "react-redux";
import { setDynamicModule } from "../../redux/actions/app.action";

function Sidebar({onSidebar}) {
    const Navigate = useNavigate()
    const dispatch = useDispatch();

    const handleDynamicModule = (item) => {
        dispatch(setDynamicModule(item))
        Navigate('/page-module');
        onSidebar();
    }

    return (
        <Box
            display={"flex"}
            flexDirection={'column'}
        >
            <Box
                sx={{
                    width:'100%',
                    p:2,
                    cursor:'pointer'
                }}
                display={"flex"}
                alignItems={"center"}
            >
                <Typography sx={{fontSize:'26px',fontWeight:'900',color:'#ffffff'}}>PROSPERITY</Typography>
            </Box>
            <Box
                sx={{
                    width:'100%'
                }}
                display={"flex"}
                flexDirection={"column"}
            >
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Dashboard</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/user');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>User</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/carousel');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Carousel</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/business-category');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Business Category</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/business');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Business</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/product-category');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Product Category</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/product');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Product</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/promo-and-news');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Promo And News</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/career');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Career</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/contact');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Contact</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/company');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Company</Typography>
                </Box>
                <Box
                    sx={{
                        p:1,
                        cursor:'pointer'
                    }}
                    onClick = {()=>{Navigate('/admin-page/social-media');onSidebar();}}
                >
                    <Typography sx={{fontSize:'14px',fontWeight:'400',color:'#ffffff'}}>Social Media</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default Sidebar;
