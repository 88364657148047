import React, { useEffect, useState } from 'react';
import { 
    Box,
    Drawer,
    Container,
} from '@mui/material';
import AdminRoute from '../../routes/admin'
import {
    SidebarDrawer,
    Header,
    Breadcrumbs,
} from "../../components"

function AdminPage() {
    const [sidebar,setSidebar] = useState(false)

    const handleSidebar = () => {
        setSidebar(!sidebar)
    }
    return (
        <Box
            sx={{
                height:'100vh',
                width:'100vw'
            }}
            display={"flex"}
            flexDirection={'row'}
        >
            <Drawer open={sidebar} onClose={()=>setSidebar(false)}>
                <Box
                    sx={{
                        width:{xs:'100vw',md:'25vw',xl:'15vw'},
                        height:'100vh',
                        backgroundColor:'#212B36',
                        boxShadow:'0px 2px 2px rgba(0,0,0,0.3)',
                        overflowY:'auto',
                    }}
                >
                    <SidebarDrawer onSidebar={handleSidebar}/>
                </Box>
            </Drawer>
            <Box
                sx={{
                    width:'100vw',
                    maxHeight:'100vh',
                    backgroundColor:'#dedede',
                    boxShadow:'0px 2px 2px rgba(0,0,0,0.3)',
                    overflowY:'auto'
                }}
                display={"flex"}
                flexDirection={"column"}
            >
                <Box
                    sx={{
                        width:'100%',
                        height:'50px',
                        backgroundColor:'#ffffff',
                        boxShadow:'0px 1px 2px 0px rgba(21, 30, 40, 0.08)',
                        px:2,
                        position:'fixed',
                        zIndex:100
                    }}
                >
                    <Box 
                        sx={{
                            height:'100%',
                            width:'100%'
                        }}
                    >
                        <Container maxWidth="xl" sx={{
                            height:'100%'
                        }}>
                            <Header onSidebar={handleSidebar} />
                        </Container>
                    </Box>
                </Box>
                {/* <Box
                    sx={{
                        backgroundColor:'#dedede',
                        px:2,
                        pt:2,
                        mt:'50px'
                    }}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Box 
                        sx={{
                            height:'100%',
                            width:'100vw'
                        }}
                    >
                        <Container maxWidth="xl" sx={{
                            height:'100%'
                        }}>
                            <Breadcrumbs />
                        </Container>
                    </Box>
                </Box> */}
                <Box
                    sx={{
                        backgroundColor:'#dedede',
                        p:2,
                        mt:'50px'
                    }}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <Box 
                        sx={{
                            height:'100%',
                            width:'100%'
                        }}
                    >
                        <Container maxWidth="xl" sx={{
                            height:'100%'
                        }}>
                            <AdminRoute />
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AdminPage;
