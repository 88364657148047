const CryptoJS = require("crypto-js");

const encryptData = (text) => {
    let encryptedText = CryptoJS.AES.encrypt(`${text}`,process.env.REACT_APP_SECRET_KEY).toString()
    return encodeURIComponent(encryptedText)
}

const decryptData = (text) => {
    let originalText = decodeURIComponent(text)
    let encryptedText = CryptoJS.AES.decrypt(originalText,process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
    return encryptedText
}

module.exports = {
    encryptData,
    decryptData,
};